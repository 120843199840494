import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = {
  key: 0,
  class: "h-full max-w-screen-xl mx-auto"
}
const _hoisted_3 = {
  "data-dusk": "unilateral-footer",
  class: "flex flex-row items-center bg-gray-800 px-3 h-10 rounded-lg space-x-4"
}
const _hoisted_4 = {
  "data-dusk": "unilateral-footer-text",
  class: "text-sm font-medium text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InitialVARefraction = _resolveComponent("InitialVARefraction")!
  const _component_MyopiaPrognosisPage = _resolveComponent("MyopiaPrognosisPage")!
  const _component_SmartFormSection = _resolveComponent("SmartFormSection")!
  const _component_SlitLampWithoutLenses = _resolveComponent("SlitLampWithoutLenses")!
  const _component_RadioJammerModal = _resolveComponent("RadioJammerModal")!
  const _component_Measurements = _resolveComponent("Measurements")!
  const _component_PatientScans = _resolveComponent("PatientScans")!
  const _component_LensOrdering = _resolveComponent("LensOrdering")!
  const _component_LensOrderingConfirmation = _resolveComponent("LensOrderingConfirmation")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!
  const _component_portal = _resolveComponent("portal")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.name,
    steps: _ctx.steps,
    loading: _ctx.loading,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    status: _ctx.status,
    onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('exit')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        loading: _ctx.loading,
        type: "button",
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.back()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_BaseButton, {
        size: "large",
        class: "ml-2",
        "right-icon": "arrow-right",
        color: _ctx.isLast ? 'success' : 'primary',
        loading: _ctx.loading,
        disabled: _ctx.loading,
        onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.isLast ? _ctx.complete() : _ctx.next()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
        ]),
        _: 1
      }, 8, ["color", "loading", "disabled"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.encounter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.step === 0)
                ? (_openBlock(), _createBlock(_component_InitialVARefraction, {
                    key: 0,
                    loading: _ctx.loading,
                    "va-refraction-required-eyes": _ctx.encounter.laterality,
                    "is-cyclopegic-refraction": _ctx.encounter?.is_cyclopegic_refraction,
                    "va-refraction-data": _ctx.encounterData?.vaRefraction,
                    "va-unit": _ctx.encounter.va_unit,
                    errors: _ctx.errors,
                    onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "va-refraction-required-eyes", "is-cyclopegic-refraction", "va-refraction-data", "va-unit", "errors"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.myopia-prognosis.name')))
                ? (_openBlock(), _createBlock(_component_MyopiaPrognosisPage, _mergeProps({
                    key: 1,
                    "patient-id": _ctx.patientId,
                    patient: _ctx.patient,
                    "journey-id": _ctx.journeyId,
                    laterality: _ctx.encounter.laterality,
                    "journey-type": _ctx.myopiaPrognosisJourneyType
                  }, _ctx.encounterData?.myopiaPrognosis, {
                    onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', $event)))
                  }), null, 16, ["patient-id", "patient", "journey-id", "laterality", "journey-type"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.patient-history.title')) && _ctx.patientHistorySection)
                ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                    key: 2,
                    class: "p-6 md:p-10",
                    "form-submission-id": _ctx.formSubmission.id,
                    "patient-id": _ctx.patientId,
                    section: _ctx.patientHistorySection,
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["form-submission-id", "patient-id", "section", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.slit-lamp.name')))
                ? (_openBlock(), _createBlock(_component_SlitLampWithoutLenses, _mergeProps({ key: 3 }, _ctx.encounterData?.slitLamp, {
                    config: _ctx.slitLampConfig,
                    errors: _ctx.errors,
                    loading: _ctx.loading,
                    laterality: _ctx.encounter?.laterality,
                    "onUpdate:conditions": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_conditions: $event
          }))),
                    "onUpdate:isNoneChecked": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update', {
            is_slit_lamp_condition_none: $event
          }))),
                    "onUpdate:details": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_details: $event
          })))
                  }), null, 16, ["config", "errors", "loading", "laterality"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.slit-lamp.name')) + 1)
                ? (_openBlock(), _createBlock(_component_RadioJammerModal, {
                    key: 4,
                    title: _ctx.$t('custom.menicon.encounter.patient-scan-instruction.title'),
                    body: _ctx.radioJammerModalBody,
                    back: _ctx.back,
                    next: _ctx.accessPatientScans,
                    skip: _ctx.skipTopographer,
                    options: _ctx.instructionsOptions
                  }, null, 8, ["title", "body", "back", "next", "skip", "options"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.measurements.name')))
                ? (_openBlock(), _createBlock(_component_Measurements, {
                    key: 5,
                    loading: _ctx.loading,
                    "va-refraction-required-eyes": _ctx.encounter && _ctx.encounter.laterality,
                    "patient-id": _ctx.patientId,
                    errors: _ctx.errors,
                    "k-unit": _ctx.encounter.k_unit,
                    data: _ctx.encounterData?.measurements,
                    onUpdate: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "va-refraction-required-eyes", "patient-id", "errors", "k-unit", "data"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.patient-scans.name')))
                ? (_openBlock(), _createBlock(_component_PatientScans, {
                    key: 6,
                    loading: _ctx.loading,
                    patient: _ctx.patient,
                    laterality: _ctx.encounter && _ctx.encounter.laterality,
                    "patient-id": _ctx.patientId,
                    errors: _ctx.errors,
                    data: _ctx.encounterData?.topographyScans,
                    "k-unit": _ctx.encounter.k_unit,
                    "show-hvid": true,
                    onUpdate: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "patient", "laterality", "patient-id", "errors", "data", "k-unit"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering.name')))
                ? (_openBlock(), _createBlock(_component_LensOrdering, _mergeProps({
                    key: 7,
                    loading: _ctx.loading,
                    errors: _ctx.errors,
                    "journey-id": _ctx.journeyId,
                    "patient-id": _ctx.patientId,
                    laterality: (_ctx.encounter && _ctx.encounter.laterality) || ''
                  }, _ctx.encounterData?.lensOrdering, {
                    "topography-scans": _ctx.encounterData?.topographyScans,
                    data: _ctx.prescriptionData,
                    "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePrescriptionData($event))),
                    onBack: _ctx.back,
                    onAccessVaPage: _ctx.accessVaPage
                  }), null, 16, ["loading", "errors", "journey-id", "patient-id", "laterality", "topography-scans", "data", "onBack", "onAccessVaPage"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering-confirmation.name')) && _ctx.prescriptionData)
                ? (_openBlock(), _createBlock(_component_LensOrderingConfirmation, {
                    key: 8,
                    loading: _ctx.loading,
                    data: _ctx.prescriptionData
                  }, null, 8, ["loading", "data"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.showUnilateralMeasurementsModal)
        ? (_openBlock(), _createBlock(_component_ActionModal, {
            key: 0,
            title: _ctx.$t('custom.menicon.encounter.measurements.unilateral-modal.title'),
            body: _ctx.$t(`custom.menicon.encounter.measurements.unilateral-modal.${_ctx.encounter.laterality}-body`),
            actions: _ctx.unilateralMeasurementsModalActions,
            icon: "bell",
            "icon-color": "white",
            "icon-bg-color": "alert-400",
            "show-close": false
          }, null, 8, ["title", "body", "actions"]))
        : _createCommentVNode("", true),
      (_ctx.showConfirmationModal)
        ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
            key: 1,
            heading: _ctx.smartFormCompleteProps.heading,
            body: _ctx.smartFormCompleteProps.body,
            "is-glyph": "",
            "icon-color": "success"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('backToPrescription')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.back-to-patient')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonLink, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.exit-to-list')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : _createCommentVNode("", true),
      (_ctx.encounter && _ctx.encounter.laterality)
        ? (_openBlock(), _createBlock(_component_portal, {
            key: 2,
            to: "workflow-footer-middle"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`custom.menicon.encounter.laterality.initial-measurement-${_ctx.encounter.laterality}`)), 1),
                (_ctx.step > _ctx.getStep(_ctx.$t('custom.menicon.encounter.va-refraction.name')))
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 0,
                      "data-dusk": "unilateral-footer-button",
                      color: "ghost",
                      size: "sm",
                      onClick: _ctx.accessVaPage
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.encounter.laterality.change')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name", "steps", "loading", "current-step", "patient", "status"]))
}