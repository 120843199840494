
import { Options, Vue } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission, JourneyTypeName, Patient } from '@/models';
import { BaseButton, ButtonLink, RadioJammerModal, SmartFormSection } from '@/lib/components';
import InitialVARefraction from '@/custom/menicon/views/encounter/initial-measurement/InitialMeasurementVARefraction.vue';
import PatientScans from '@/custom/menicon/views/encounter/PatientScans.vue';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import MyopiaPrognosisPage from '@/custom/menicon/views/encounter/MyopiaPrognosisPage.vue';
import { ILensOrderingAnswer } from '@/custom/menicon';
import SmartFormComplete from '@/lib/components/SmartForm/SmartFormComplete.vue';
import { PatientJourneyService } from '@/services/api';
import { EncounterData } from '@/helpers/encounter.helper';
import { countErrors, validate } from '@/helpers/validators.helper';
import { SavingStatus } from '@/lib/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import Measurements from '@/custom/menicon/views/encounter/Measurements.vue';
import ActionModal from '@/lib/components/Modals/ActionModal.vue';
import { IModalAction } from '@/lib';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    ActionModal,
    Measurements,
    SmartFormComplete,
    MyopiaPrognosisPage,
    InitialVARefraction,
    SmartFormSection,
    PatientScans,
    LensOrdering,
    LensOrderingConfirmation,
    WorkflowLayout,
    BaseButton,
    ButtonLink,
    RadioJammerModal,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    journeyId: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    formSubmission: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    directOrder: {
      type: Boolean,
      default: true
    },
    journeyType: {
      type: String,
      default: null
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    encounterData: {
      type: Object,
      default: null
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string | null;
  patientId!: string;
  journeyId!: string;
  patient!: Patient;
  encounter!: Encounter;
  formSubmission!: FormSubmission | null;
  encounterData!: EncounterData | null;
  directOrder!: boolean;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  prescriptionData: ILensOrderingAnswer | null = null;
  showUnilateralMeasurementsModal = false;

  patientHistorySectionIds = {
    both: '787216b4-c692-43d0-bbdb-ca905ce55519',
    right: 'e090607c-73eb-4840-b7f1-d4b756fd4448',
    left: 'dbe33f47-1d94-4be2-b9f8-65a98604ed50'
  };

  journeyService = new PatientJourneyService(this.patientId);
  isBloomDayEnabled = isFeatureFlagEnabled(FEATURES.BLOOM_DAY);

  myopiaPrognosisJourneyType = this.isBloomDayEnabled ? null : JourneyTypeName.MENICON_BLOOM_NIGHT;

  hasSkippedTopographer = false;

  get unilateralMeasurementsModalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('custom.menicon.encounter.measurements.unilateral-modal.return') as string,
        onClick: () => {
          this.accessVaPage();
        }
      },
      {
        color: 'ghost',
        label: this.$t('platform.common.back') as string,
        onClick: () => {
          this.back();
        }
      }
    ];
  }

  get instructionsOptions() {
    return [
      {
        label: this.$t('platform.common.back') as string,
        value: 'Back',
        position: 'left',
        color: 'primary-600',
        icon: 'chevron-left',
        iconPosition: 'left',
        function: 'back'
      },
      ...(this.isBloomDayEnabled
        ? [
          {
            label: this.$t('custom.menicon.encounter.initial-patient-scan-instruction.skip-topographer') as string,
            value: 'Skip',
            position: 'right',
            color: 'primary-600',
            icon: 'chevron-right',
            iconPosition: 'right',
            function: 'skip'
          }
        ]
        : []),
      {
        label: this.$t('custom.menicon.encounter.initial-patient-scan-instruction.scans-complete') as string,
        value: 'Complete',
        position: 'right',
        color: 'primary',
        icon: 'tick',
        iconPosition: 'left',
        function: 'next'
      }
    ];
  }

  get patientHistorySectionId(): string {
    return this.encounter?.laterality
      ? this.patientHistorySectionIds[this.encounter.laterality]
      : this.patientHistorySectionIds.both;
  }

  get isPrognosisEnabled() {
    return isFeatureFlagEnabled(FEATURES.MYOPIA_PROGNOSIS);
  }

  get steps(): string[] {
    return [
      this.$t('custom.menicon.encounter.va-refraction.name') as string,
      ...(this.isPrognosisEnabled ? [this.$t('custom.menicon.encounter.myopia-prognosis.name') as string] : []),
      this.$t('custom.menicon.encounter.patient-history.title') as string,
      this.$t('custom.menicon.encounter.slit-lamp.name') as string,
      '',
      ...(this.hasSkippedTopographer
        ? [this.$t('custom.menicon.encounter.measurements.name') as string]
        : [this.$t('custom.menicon.encounter.patient-scans.name') as string]),
      this.$t('custom.menicon.encounter.lens-ordering.name') as string,
      this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string
    ];
  }

  get isLast(): boolean {
    return this.step === this.steps.length - 1;
  }

  get buttonLabel(): string {
    return this.step < this.steps.length - 1
      ? (this.$t('platform.common.next') as string)
      : (this.$t('platform.common.complete') as string);
  }

  get radioJammerModalBody() {
    return this.isBloomDayEnabled
      ? this.$t('custom.menicon.encounter.initial-patient-scan-instruction.body-with-bloom-day')
      : this.$t('custom.menicon.encounter.initial-patient-scan-instruction.body');
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get patientHistorySection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.patientHistorySectionId
    );
  }

  get currentSectionId(): string | null {
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.patient-history.title') as string)) {
      return this.patientHistorySectionId;
    }
    return null;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  get smartFormCompleteProps() {
    if (this.directOrder) {
      return {
        heading: this.$t('custom.menicon.prescriptions.order-placed-heading') as string,
        body: this.$t('custom.menicon.prescriptions.order-placed-body') as string
      };
    }

    return {
      heading: this.$t('custom.menicon.prescriptions.consult-complete-heading') as string,
      body: this.$t('custom.menicon.prescriptions.consult-complete-body') as string
    };
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  accessVaPage() {
    this.showUnilateralMeasurementsModal = false;
    this.$emit('clearErrors', {});
    this.$router.replace({ query: {} });
  }

  accessPatientScans() {
    this.hasSkippedTopographer = false;
    const isRightFilled =
      !this.encounter.r_mls_id && (this.encounter.r_flat_k || this.encounter.r_steep_k || this.encounter.r_flat_k_axis);
    const isLeftFilled =
      !this.encounter.l_mls_id && (this.encounter.l_flat_k || this.encounter.l_steep_k || this.encounter.l_flat_k_axis);
    if (isLeftFilled || isRightFilled) {
      this.$emit('update', {
        ...(isLeftFilled ? { l_hvid: null, l_flat_k: null, l_steep_k: null, l_flat_k_axis: null } : {}),
        ...(isRightFilled ? { r_hvid: null, r_flat_k: null, r_steep_k: null, r_flat_k_axis: null } : {})
      });
    }
    this.next();
  }

  skipTopographer() {
    this.hasSkippedTopographer = true;
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.measurements.name') as string)) {
      return;
    }

    // We need to clear the mlsId and topographer details to be null here when selecting skip topographer
    this.$emit('update', {
      ...(this.encounter.l_mls_id
        ? {
          l_hvid: null,
          l_flat_k: null,
          l_steep_k: null,
          l_flat_k_axis: null,
          l_mls_id: null
        }
        : {}),
      ...(this.encounter.r_mls_id
        ? {
          r_hvid: null,
          r_flat_k: null,
          r_steep_k: null,
          r_flat_k_axis: null,
          r_mls_id: null
        }
        : {})
    });
    this.next();
    if (this.encounter?.laterality) {
      this.showUnilateralMeasurementsModal = true;
    }
  }

  complete() {
    this.$emit('complete', { sectionId: this.currentSectionId, order: this.prescriptionData });
  }

  validateCurrentStep() {
    let errors = {};
    if (this.step === 0 && this.rules?.vaRefraction) {
      errors = validate(this.rules.vaRefraction, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.patient-scans.name') as string) &&
      this.rules?.patientScans
    ) {
      errors = validate(this.rules.patientScans, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.measurements.name') as string) &&
      this.rules?.measurements
    ) {
      errors = validate(this.rules.measurements, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.lens-ordering.name') as string) &&
      this.prescriptionData
    ) {
      errors = this.prescriptionData.errors;
    }

    this.$emit('setErrors', errors);
  }

  updatePrescriptionData(data: ILensOrderingAnswer) {
    this.prescriptionData = data;
    if (this.errorCount) {
      this.validateCurrentStep();
    }
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }

  back() {
    this.showUnilateralMeasurementsModal = false;
    this.$emit('back');
  }
}
